<template>
  <el-dialog
    class="dialog"
    :title="title"
    v-bind="$attrs"
    width="450px"
    :visible="true"
    :close-on-click-modal="false"
    @close="$emit('close')"
  >
    <BaseElForm ref="formRef" :model="formData" :rules="formRules" label-position="top">
      <BaseElFormItem label="匯出日期" prop="businessDataAutoSheetRecordId">
        <BaseElSelect
          v-model="formData.businessDataAutoSheetRecordId"
          placeholder="請選擇"
        >
          <BaseElSelectOption
            v-for="action in dailyMember"
            :key="action.businessDataAutoSheetRecordId"
            :label="formatDate(action.exportedAt, 'YYYY/MM/DD HH:mm:ss')"
            :value="action.businessDataAutoSheetRecordId"
          />
        </BaseElSelect>
      </BaseElFormItem>
      <BaseElFormItem label="匯出格式" prop="format">
        <BaseElSelect
          v-model="formData.format"
          style="width: 100%"
          placeholder="請選擇格式"
        >
          <BaseElSelectOption
            v-for="item in exportFormatOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </BaseElSelect>
      </BaseElFormItem>
    </BaseElForm>
    <p v-if="info" class="text-base text-sub">{{ info }}</p>
    <template #footer>
      <BaseElButton plain @click="$emit('close')">取消</BaseElButton>
      <BaseElButton
        type="primary"
        style="margin-left: 12px"
        @click="onSubmit"
      >
        確認
      </BaseElButton>
    </template>
  </el-dialog>
</template>

<script>
import { exportFormatOptions } from '@/config/export'
import { computed, reactive } from 'vue'
import { formatDate } from '@/utils/date'
import { noEmptyRules } from '@/validation'
import { useBaseForm } from '@/use/useForm'

export default {
  name: 'ExportOptionsDialog',
  props: {
    title: {
      type: String,
      default: '匯出',
    },
    info: {
      type: String,
      default: '',
    },
    dailyMember: {
      type: Array,
      default: () => [],
    }
  },
  setup (props, { emit }) {
    const { formRef, formData, initFormData, checkForm } = useBaseForm()
    initFormData({
      businessDataAutoSheetRecordId: null,
      format: 'excel',
    })
    const formRules = {
      businessDataAutoSheetRecordId: [noEmptyRules('請選擇')],
      format: [noEmptyRules('請選擇')],
    }
    const onSubmit = async () => {
      if (!(await checkForm(formRef.value))) return
      emit('download', formData)
    }
    return {
      exportFormatOptions,
      formData,
      formRef,
      formatDate,
      formRules,
      onSubmit,
    }
  },
}
</script>

<style scoped lang="scss">
.dialog {
  text-align: left;
}

::v-deep .el-input {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-select {
  width: 100% !important;
  max-width: 400px !important;
}
::v-deep .el-input__inner {
  width: 100% !important
}
</style>
